import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import client from '../client';

function Delete({ location }) {
    const { t } = useTranslation();
    const id = location.search.split('?id=').pop();
    const [success, setSuccess] = useState();
    const [errors, setErrors] = useState();

    useEffect(() => {
        id && client.deleteData(`/reservations?id=${id}`)
            .then(({ errors, success }) => {
                if (success && !errors) {
                    setSuccess(true)
                }
                else {
                    setErrors(errors);
                }
            });
    }, [id]);


    return (
        <div className="vh-100 page-wrap d-flex flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <table border={0} cellPadding={0} cellSpacing={0} style={{ margin: '0 auto', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: '15px', lineHeight: '23px' }}>
                            <tbody><tr>
                                <td style={{ padding: '10px 0 30px 0' }}>
                                    <table align="center" border={0} cellPadding={0} cellSpacing={0} className="reservation-table" width={600} style={{ border: '1px solid #cccccc', borderCollapse: 'collapse' }}>
                                        <tbody><tr>
                                            <td align="left" style={{ background: "linear-gradient(to right, #002857 24%, rgba(1, 38, 85, 0.6) 66%, rgba(0, 40, 87, 0.3) 100%)", padding: '18px 24px' }}>
                                                <h1 id="logo"><span>MyPriority.Shop</span></h1>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" style={{ color: '#000', fontSize: '15px', padding: '24px' }}>
                                                <p style={{ margin: '0 0 15px' }}>Gentile Cliente,</p>
                                                <p style={{ margin: '0 0 15px' }}>Ti confermiamo la cancellazione della prenotazione</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td align="left" style={{ color: '#000', fontSize: '15px', padding: '24px' }}>
                                                <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                                                    <li style={{ display: 'inline', margin: '0 15px 0 0' }}>
                                                        <a href="https://www.mypriority.shop/terms" style={{ color: '#888', textDecoration: 'none' }}>Termini e condizioni</a>
                                                    </li>
                                                    <li style={{ display: 'inline' }}>
                                                        <a href="https://www.mypriority.shop/privacy" style={{ color: '#888', textDecoration: 'none' }}>Privacy</a>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                        </tbody></table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Delete;