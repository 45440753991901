import React, { useEffect, useState, Fragment, useRef } from "react";
import { withRouter, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import './Booking.scss';
import client from '../client';
import { Steps } from '../steps/Steps';
import dataForSpecialMalls from '../data/DataForSpecialMalls'

function Booking({
  history
}) {
  const { t } = useTranslation();
  const resultsList = useRef();
  const [loading, setLoading] = useState(false);
  const [places, setPlaces] = useState([]);
  const [malls, setMalls] = useState([]);
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [errors, setErrors] = useState();
  const [hasScroll, setScroll] = useState(false);

  const targetList = search ? filtered : places;
  const { id } = useParams();
  localStorage.setItem('mallId', id);

  function getMallName(id) {
    const target = malls.find(mall => mall.id === id);
    return target.title;
  }

  function choosePlace(place) {
    history.push(`/reservations/${id}/persons`, place)
  }

  useEffect(() => {
    setLoading(true);
    client.getData(`/malls/client_list`)
      .then(({ malls, errors, success }) => {
        if (success && !errors) {
          setMalls(malls);

          client.getData(`/places/client_list?mall_alias=${id}`)
            .then(({ places, errors, success }) => {
              if (success && !errors) {
                setPlaces(places);
                setLoading(false);
              }
              else {
                setErrors(errors);
                setLoading(false);
              }
            });
        }
        else {
          setErrors(errors);
          setLoading(false);
        }
      });
  }, [id]);

  setTimeout(() => {
    const scrollHeight = resultsList?.current?.scrollHeight;
    const clientHeight = resultsList?.current?.clientHeight;
    setScroll(scrollHeight > clientHeight)
  }, 0);

  const specialHeadlineForMallsLayout = (
    <h2 className="mt-2">
      <div className="special-headline-for-mall">
        {id && <img
          style={{
            width:"100%",
            maxWidth: "500px",
            margin: "30px 0"
          }}
          src={`https://api.mypriority.shop/logos/${id}.png`}
          alt="logo" />}
        <div className="company-text">
          <strong style={{color: dataForSpecialMalls.generalStyles.fontColor}}>{t('booking.start-booking')}</strong>
          <span>{t('booking.choose-place')}</span>
        </div>
      </div>
    </h2>);
  
  const generalHeadlineLayout = (
    <h2 className="mt-2">
      {id && <img src={`https://api.mypriority.shop/logos/${id}.png`} width="200px" height="81px" alt="logo" />}
      <div className="company-text">
        <strong>{t('booking.start-booking')}</strong>
        <span>{t('booking.choose-place')}</span>
      </div>
    </h2>);

  const generalParagraphLayout = (
    <>
      <p className="paragraphInStore mt-4 mb-1">{t('booking.not-wait')}</p>
      <p className="paragraphInStore mb-4 mt-1">{t('booking.list-of-stores')}</p>
    </>
  )

  const specialParagraphForMallsLayout = (
    <>
      <p className="paragraphInStore mt-4 mb-1" style={{color: dataForSpecialMalls.generalStyles.fontColor}}>{t('booking.special.not-wait')}</p>
      <p className="paragraphInStore mb-4 mt-1" style={{color: dataForSpecialMalls.generalStyles.fontColor}}>{t('booking.special.list-of-stores')}</p>
    </>
  )

  return (
    <div id="Booking" className="component">
      <div className="container">
        <div className="frame">
          {dataForSpecialMalls[id] ? specialHeadlineForMallsLayout : generalHeadlineLayout}
          <Steps step={1} />
          {dataForSpecialMalls[id] ? specialParagraphForMallsLayout : generalParagraphLayout}
          {errors && errors.length && errors.map(({ key, messages }, index) =>
            <div key={index} className="alert alert-danger mt-3 mb-2 rounded" role="alert">
              {messages && messages.map((message, index) =>
                <p key={index}>{t('errors.error')} ({key}): {message}</p>
              )}
            </div>
          )}
          {loading ? (
            <p>Loading ...</p>
          ) : (
            <Fragment>
              <form className="search-bar">
                <div className="field">
                  <button><i className="fas fa-search"></i></button>
                  <input
                    className="search-input"
                    type="text"
                    placeholder={t('booking.search-placeholder')}
                    value={search}
                    onChange={e => {
                      const filtered = places.filter(place => {
                        return place.title.toLowerCase().includes(e.target.value.toLowerCase());
                      });

                      setFiltered(filtered);
                      setSearch(e.target.value);
                    }}
                  />
                </div>
              </form>
              <div className="gradient-holder">
                <ul
                  ref={resultsList}
                  className={`results-list${dataForSpecialMalls[id] ? ' specialMalls' : ''}`}
                  data-customscroll={hasScroll}>
                  {targetList && targetList.map(place => (
                    <li
                      key={place.id}
                      onClick={() => choosePlace({
                        title: place.title,
                        id: place.id,
                        max_persons: place.max_persons,
                        mall: getMallName(place.mall_id)
                      })}
                    >
                      <strong style={{color: `${dataForSpecialMalls[id] && dataForSpecialMalls.generalStyles.fontColor}`}}>{place.title}</strong>
                    </li>
                  ))}
                </ul>
                <span className="gradient"></span>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(Booking);
