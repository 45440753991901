import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../utils';
import client from '../client';
import { Steps } from './Steps';
import dataForSpecialMalls from '../data/DataForSpecialMalls';

function Reservation({ history, location }) {
  const { t, i18n } = useTranslation();

  const [currentReservation, setCurrentReservation] = useState({});
  const [sendStatus, setSendStatus] = useState(false);
  const [email, setEmail] = useState();
  const [url, setUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const { id } = useParams();
  const mallId = localStorage.getItem('mallId');

  function formatDate(d) {
    if (d) {
      const day = d.getDate();
      const month = d.toLocaleString(i18n.language === 'it' ? 'it-IT' : 'en-GB', { month: 'long' });

      return `${day} ${month}`;
    }
  }
  const prenotaHandler = (e) => {
    e.preventDefault()
    history.push(`/${mallId}`)
  }
  function proceedReservation() {
    const timeValues = currentReservation.time.split(' - ');

    setLoading(true);
    client.postData(`/places/${currentReservation.place.id}/reservations`, {
      date: currentReservation.parsedDate,
      visitors_number: currentReservation.persons,
      from: timeValues[0],
      to: timeValues[1],
      email
    })
      .then(({ errors, success }) => {
        if (success && !errors) {
          setSendStatus(true);
          setLoading(false);
        }
        else {
          setSendStatus(false);
          setErrors(errors);
          setLoading(false);
        }
      });
  }

  useEffect(() => {
    setCurrentReservation(location.state);
  }, [location.state]);
  useEffect(() => {
    client.getData(`/malls/info?mall_alias=${id}`)
      .then(({ url, success }) => {
        if (success) {
          setUrl(url);
        }
        else {
          setLoading(false);
        }
      });
  }, [id])

  const isSpecialMall = !!dataForSpecialMalls[id];

  const logoForSpecialMalls = (
    <img className="logoForSpecialMalls" src={`https://api.mypriority.shop/logos/${id}.png`} alt="logo" />
  )

  const generalQrLabel = (
    <label>{t('reservation.email-label')}</label>
  )

  const specialForMallsQrLabel = (
    <label style={{ fontFamily: "sf_pro_displayregular" }}>{t('reservation.special.email-label')}</label>
  )

  const generalAnotherShopButtonText = (
    t('reservation.another-shop')
  )

  const specialForMallsAnotherShopButtonText = (
    t('reservation.special.another-shop')
  )

  const generalShowQRText = (
    <p>{t('reservation.show-qr-code')}</p>
  )

  const specialForMallsShowQRText = (
    <p>{t('reservation.special.show-qr-code')}</p>
  )

  return (
    <div className="booking-page">
      <div className="border-row">
        <div className="container">
          <div className="frame">
            <header className="info-wrapper">
              <div className="info">
                {!sendStatus &&
                  <button
                    className="go-back"
                    style={{ color: `${isSpecialMall ? dataForSpecialMalls[id].mainColor : '#3170f5'}` }}
                    onClick={() => history.push(`/reservations/${id}/timeline`)}
                  >
                    <svg className="arrow-circle-left" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path
                        fill={`${isSpecialMall ? dataForSpecialMalls[id].mainColor : '#3170f5'}`}
                        d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z"
                      />
                    </svg>
                    <span>{t('reservation.go-back-time')}</span>
                  </button>
                }
                <div className="row header-row">
                  {currentReservation?.place &&
                    <div className="col-md-8">
                      <h1>{t('reservation.your-choose')} <strong>{currentReservation.place.title}</strong></h1>
                    </div>
                  }
                </div>
              </div>
              {isSpecialMall && logoForSpecialMalls}
            </header>
          </div>
        </div>
      </div>
      <div className="container">
        {!sendStatus && <Steps step={5} />}
        <div className="row">
          <div className="col">
            <p className="booking-persons">{t('reservation.persons')}: {currentReservation.persons}</p>
            <p className="booking-date">{t('reservation.date')}: {formatDate(currentReservation.date)}</p>
            <p className="booking-time">{t('reservation.time')}: {isSpecialMall && currentReservation.time ? currentReservation.time.split(' - ')[0] : currentReservation.time}</p>
            <form className="booking-form">
              {isSpecialMall ? specialForMallsQrLabel : generalQrLabel}
              <div className="input-row">
                <div className="booking-agreement">
                  {t('reservation.agreement-i-accept')} <a href="https://www.mypriority.shop/privacy" target="_blank" rel="noopener noreferrer">
                    {t('reservation.agreement-privacy')}
                  </a> {t('reservation.agreement-and')} <a href="https://www.mypriority.shop/terms" target="_blank" rel="noopener noreferrer">
                    {t('reservation.agreement-terms-and-conditions')}
                  </a>
                  <input
                    className="booking-agreement-checkbox"
                    type="checkbox"
                    checked={agreement}
                    onChange={(event) => {
                      setAgreement(event.target.checked);
                    }}
                  />
                </div>
                <input
                  className="booking-email-input"
                  type="text"
                  placeholder={t('reservation.email-text')}
                  onChange={(e) => setEmail(e.target.value.trim())}
                  value={email || ""}
                />
                <button
                  disabled={sendStatus || !validateEmail(email) || !agreement}
                  style={{ background: `${isSpecialMall ? dataForSpecialMalls[id].mainColor : '#deb400'}` }}
                  className={sendStatus ? "sent" : undefined}
                  onClick={(e) => {
                    e.preventDefault();
                    proceedReservation();
                  }
                  }
                >
                  {sendStatus ? (<i className="ico-ok">{t('reservation.sended')}</i>) : t('reservation.send')}
                </button>
              </div>
              {loading && <p>Loading...</p>}
              {sendStatus &&
                <div className="message">
                  <p>{t('reservation.please')} <strong>{t('reservation.check-email')}</strong></p>
                  {isSpecialMall ? specialForMallsShowQRText : generalShowQRText}
                  <div>
                    <div className="shop-form-actions" style={{ marginTop: 30 }}>
                      <button id='buttonContainer'>
                        <a
                          style={{ background: `${isSpecialMall ? dataForSpecialMalls[id].mainColor : '#3170f5'}` }}
                          id='email-button-res'
                          href={url}
                        >
                          {t('reservation.home')}
                        </a>
                      </button>
                      <button
                        className='booking-black'
                        onClick={prenotaHandler}
                      >
                        {isSpecialMall ? specialForMallsAnotherShopButtonText : generalAnotherShopButtonText}
                      </button>
                    </div>
                  </div>
                </div>
              }
              {errors && errors.length && errors.map(({ key, messages }, index) =>
                <div key={index} className="alert alert-danger mt-3 mb-2 rounded" role="alert">
                  {messages && messages.map((message, index) =>
                    <p key={index}>{t('errors.error')} ({key}): {message}</p>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Reservation);
