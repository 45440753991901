import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import client, {api_url} from '../client';

import {getItalianDate, getTimeFromDate} from '../utils';

function Confirmation({location}) {
    const {t} = useTranslation();
    const id = location.search.split('?id=').pop();
    const [errors, setErrors] = useState();
    const [data, setData] = useState();
    const [date, setDate] = useState();
    const [hours, setHours] = useState();

    function cutApiUrl(url) {
        return url.replace('/api/v1', '');
    }

    useEffect(() => {
        id && client.updateData(`/reservations/confirmation?id=${id}`)
            .then(({errors, success}) => {
                if (success && !errors) {
                    client.getData(`/reservations?id=${id}`)
                        .then((data) => {
                            setData(data);
                            setDate(getItalianDate(data.date_from));
                            setHours({
                                from: getTimeFromDate(data.date_from),
                                to: getTimeFromDate(data.date_to),
                            })
                        }).catch(errors => {
                        setErrors(errors);
                    });
                } else {
                    setErrors(errors);
                }
            }).catch(errors => {
                setErrors(errors);
            });
    }, [id]);

    return (
        <div className="vh-100">
            <div className="text-center">
                {data && !errors &&
                <table className="reservation-confirmation-table" border="0" cellPadding="0" cellSpacing="0" width="600"
                       style={{
                           margin: "0 auto",
                           "fontFamily": "Arial, Helvetica, sans-serif",
                           "fontSize": "15px",
                           "lineHeight": "23px"
                       }}>
                    <tbody>
                    <tr>
                        <td style={{"padding": "10px 10px 30px"}}>
                            <table align="center" border="0" cellPadding="0" cellSpacing="0" width="100%"
                                   style={{"border": "1px solid #cccccc", "borderCollapse": "collapse"}}>
                                <tbody>
                                <tr>
                                    <td align="left" style={{
                                        background: "linear-gradient(to right, #002857 24%, rgba(1, 38, 85, 0.6) 66%, rgba(0, 40, 87, 0.3) 100%)",
                                        padding: '18px 24px'
                                    }}>
                                        <h1 id="logo"><span>MyPriority.Shop</span></h1>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left" style={{"color": "#000", "fontSize": "15px", "padding": "24px"}}>
                                        <p style={{"margin": "0 0 15px"}}>{t('confirmation.dear-customer')},</p>
                                        {hours &&
                                        <p style={{"margin": "0 0 15px"}}>{t('confirmation.we-confirm')} <strong><span
                                            className="text-capitalize">{date}</span> {t('confirmation.hour-from')} {hours.from} {t('confirmation.hour-to')} {hours.to}
                                        </strong> {t('confirmation.in-the-store')}
                                            <strong>{data.place_title}</strong> {t('confirmation.was-confirmed')}.</p>}
                                        <p style={{"margin": "0 0 15px"}}>
                                            <strong>{t('confirmation.people-number')}</strong>: {data.visitors_number}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="left" style={{"color": "#000", "fontSize": "15px", "padding": "24px"}}>
                                        <p style={{"margin": "0 0 25px"}}>Sarà necessario presentarsi presso il
                                            negozio <strong>{data.place_title}</strong> ed <strong>esibire all'ingresso
                                                il codice QR </strong> qui sotto.</p>
                                        <p style={{"margin": "0 0 25px"}}>Sarà possibile visualizzare il codice QR in
                                            ogni momento seguendo <strong>il link ricevuto via SMS.</strong></p>
                                        <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{
                                            "margin": "0 0 40px",
                                            "fontFamily": "Arial, Helvetica, sans-serif; font-size: 15px"
                                        }}>
                                            <tbody>
                                            <tr className="reservation-confirmation-table-qr">
                                                <td width="200" style={{
                                                    "textAlign": "center",
                                                    "verticalAlign": "top",
                                                    "paddingRight": "25px",
                                                    "borderRight": "1px solid #d8d8d8"
                                                }}>
                                                    <img src={`${api_url}${cutApiUrl(data.qr)}`} alt="qr" width="200"
                                                         style={{"display": "block"}}/>
                                                </td>
                                                <td style={{"verticalAlign": "top", "paddingLeft": "25px"}}>

                                                    <p style={{"margin": "0 0 15px"}}>{t('confirmation.cancel-recording')}
                                                        <a href={`/reservations?id=${id}`} style={{
                                                            "color": "#3170f5",
                                                            "textDecoration": "none"
                                                        }}>{t('confirmation.click-here')}</a>.</p>

                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <ul style={{"margin": "0", "padding": "0", "listStyle": "none"}}>
                                            <li style={{"display": "inline", "margin": "0 15px 0 0"}}>
                                                <a href="/privacy" style={{
                                                    "color": "#888",
                                                    "textDecoration": "none"
                                                }}>{t('contact.privacy')}</a>
                                            </li>
                                            <li style={{"display": "inline"}}>
                                                <a href="/terms" style={{
                                                    "color": "#888",
                                                    "textDecoration": "none"
                                                }}>{t('contact.terms')}</a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                }
                {errors && errors.length && errors.map(({key, messages}, index) =>
                    <div key={index} className="alert alert-danger mt-3 mb-2 rounded" role="alert">
                        {messages && messages.map((message, index) =>
                            <p key={index}>{t('errors.error')} ({key}): {message}</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Confirmation;
