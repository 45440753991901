import React from 'react';
import './Step.scss';

export const Step = ({ activeStep, currentStep, title, backgroundOfActiveStep }) => {
    return (
        <div className="steps__step">
            <div className="steps__step-wrapper">
                <div 
                    className={`steps__number ${activeStep === currentStep ? 'steps__number--active' : ''} steps__line`}
                    style={{background: `${activeStep === currentStep ? backgroundOfActiveStep : ''}`}}
                >
                    {currentStep}
                </div>
            </div>
            <div>
                <h4 className="steps__title">{title}</h4>
            </div>
        </div>
    )
};