import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { store } from './platf/store';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import './index.scss';

import './i18n';


ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <Provider store={store}>
      <GoogleReCaptchaProvider
        reCaptchaKey="6LeSm_oUAAAAAPOFigAGjcT0nFKSxMVvh76CY6iu"
      >
        <App />
      </GoogleReCaptchaProvider>
        </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

