import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addPerson, removePerson, setCurrentPlace } from '../platf/actionsCreators';
import { Steps } from './Steps';
import dataForSpecialMalls from '../data/DataForSpecialMalls';

import './styles.scss';
import client from "../client";
import BigLoader from "../components/common/loaders/BigLoader";
const Persons = ({
    history,
    location,
    persons,
    addPerson,
    removePerson,
    setCurrentPlace,
    currentPlace,
    match
}) => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const placeId = urlParams.get('place_id');

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [places, setPlaces] = useState([]);
    const [currentPlaceId, setCurrentPlaceId] = useState(parseInt(placeId));

    useEffect(() => {
        if (location.state) {
            setCurrentPlace(location.state);
        }

        if (!currentPlace.max_persons) {
            setErrors([...errors, 'Can not find corresponding place. Please check Id!'])
        } else {
            setErrors([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPlace, location.state, setCurrentPlace]);

    useEffect(() => {
        if (placeId) {
            setCurrentPlaceId(placeId);
            setLoading(true);

            client.getData(`/places/client_list?mall_alias=${id}`)
                .then(({ places, errors, success }) => {
                    if (success && !errors) {
                        setSelfPlace(places);
                        setPlaces(places);
                        setLoading(false);
                    }
                    else {
                        setErrors([...errors, 'Can not get Places!']);
                        setLoading(false);
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setSelfPlace(places) {
        if (places.length > 0) {
            places.map(place => {
                if (place.id === currentPlaceId) {
                    setCurrentPlace(place);
                }
            })
        }
    }

    const formatDate = (d) => {
        const day = d.getDate();
        const month = d.toLocaleString(i18n.language === 'it' ? 'it-IT' : 'en-GB', { month: 'long' });

        return `${day} ${month}`;
    };

    const logoForSpecialMalls = (
        <img className="logoForSpecialMalls" src={`https://api.mypriority.shop/logos/${id}.png`} alt="logo" />
    )

    const generalQuestion = (
        <h2>{t('reservation.how-many')} {currentPlace.max_persons && <span>(Max: {currentPlace.max_persons})</span>}?</h2>
    )

    const specialQuestionForMalls = (
        <h2 style={{
            color: dataForSpecialMalls.generalStyles.fontColor,
            fontFamily: 'sf_pro_displayregular'
            }}>{t('reservation.special.how-many')} {currentPlace.max_persons && <span>(max: {currentPlace.max_persons})</span>}
        </h2>
    )

    return (
        <div className="booking-page">
            <div className="container">
                <div className="frame">
                    <header className="info-wrapper">
                        <div className="info">
                            <button
                                className="go-back"
                                style={{color: `${dataForSpecialMalls[id] ? dataForSpecialMalls[id].mainColor : '#3170f5'}`}}
                                onClick={() => {
                                const params = match.params || {};

                                if (typeof params.id !== 'undefined') {
                                    history.push(`/${match.params.id}`)
                                } else {
                                    history.goBack()
                                }
                            }}>
                                <svg className="arrow-circle-left" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        fill={`${dataForSpecialMalls[id] ? dataForSpecialMalls[id].mainColor : '#3170f5'}`}
                                        d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z"/>
                                </svg>
                                <span>{t('reservation.go-back-place')}</span>
                            </button>
                            <h1>{t('reservation.your-choose')} <strong>{currentPlace.title}</strong></h1>
                        </div>
                        {dataForSpecialMalls[id] && logoForSpecialMalls}
                    </header>
                    <Steps step={2} />
                    {loading ? <BigLoader /> : (
                        <main>
                            <div className="row">
                                <div className="col">
                                    {dataForSpecialMalls[id] ? specialQuestionForMalls : generalQuestion}
                                    <div className="number-select">
                                        <button
                                            className={persons !== 1 ? 'minus' : 'minus disabled'}
                                            onClick={() => persons !== 1 && removePerson()}
                                        >-</button>
                                        <input type="text" value={`${persons}`} readOnly={true} />
                                        <button
                                            className={persons !== currentPlace.max_persons ? 'plus' : 'plus disabled'}
                                            onClick={() => persons !== currentPlace.max_persons && addPerson()}
                                        >+</button>
                                    </div>
                                    {(errors.length > 0) && errors.map((error, index) => {
                                        return <div key={index} className="alert alert-danger" role="alert">
                                            {error}
                                        </div>
                                    })}
                                    <button
                                        type="submit"
                                        style={{background: `${dataForSpecialMalls[id] ? dataForSpecialMalls[id].mainColor : '#3170f5'}`}}
                                        className="continue-btn"
                                        onClick={() => {
                                            history.push(`/reservations/${id}/calendar`)
                                        }}
                                    >{t('reservation.continue')}</button>
                                </div>
                            </div>
                        </main>
                    )}
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = ({ steps }) => (
    {
        persons: steps.persons,
        currentPlace: steps.currentPlace,
    }
);

export default withRouter(connect(mapStateToProps, {
    addPerson,
    removePerson,
    setCurrentPlace
})(Persons));
