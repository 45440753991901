import * as action from '../constants';

const INITIAL_STATE = {
    persons: 1,
    currentPlace: {},
    currentTime: '',
    currentDate: new Date(),
    loading: false,
    slotsList: [],
    errors: null
};

export const steps = (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case action.ADD_PERSON: {
            return {
                ...state,
                persons: state.persons + 1
            }
        }

        case action.REMOVE_PERSON: {
            return {
                ...state,
                persons: state.persons - 1
            }
        }

        case action.SET_CURRENT_PLACE: {
            return {
                ...state,
                currentPlace: actions.payload
            }
        }

        case action.SET_CURRENT_DATE: {
            return {
                ...state,
                currentDate: actions.payload
            }
        }

        case action.SET_CURRENT_TIME: {
            return {
                ...state,
                currentTime: actions.payload
            }
        }

        case action.TIME_SLOTS_LIST_LOAD: {
            return {
                ...state,
                loading: true
            }
        }
        
        case action.TIME_SLOTS_LIST_LOAD_SUCCESS: {
            return {
                ...state,
                slotsList: actions.payload,
                loading: false
            }
        }

        case action.TIME_SLOTS_LIST_LOAD_FAILURE: {
            return {
                ...state,
                errors: actions.payload,
                loading: false
            }
        }

        default:
            return state;
    }

};