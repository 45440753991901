import React from 'react';
import { connect } from 'react-redux';
import { withRouter,useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import enGb from 'date-fns/locale/en-GB';
import itIt from 'date-fns/locale/it';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Steps } from './Steps';

import { setCurrentDate, setCurrentPlace } from '../platf/actionsCreators';
import dataForSpecialMalls from '../data/DataForSpecialMalls';
import './styles.scss';

const Calendar = ({
    currentDate,
    setCurrentDate,
    setCurrentPlace,
    persons,
    currentPlace,
    history,
}) => {
    registerLocale('it-IT', itIt);
    registerLocale('en-GB', enGb);

    const { t, i18n } = useTranslation();
    const subDays = (d) => d.setDate(d.getDate());
    const {id} = useParams()
    //TODO вынести format date
    const formatDate = (d) => {
        const day = d.getDate();
        const month = d.toLocaleString(i18n.language === 'it' ? 'it-IT' : 'en-GB', { month: 'long' });

        return `${day} ${month}`;
    };

    const addDays = (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    
    const logoForSpecialMalls = (
        <img className="logoForSpecialMalls" src={`https://api.mypriority.shop/logos/${id}.png`} alt="logo" />
    )

    const generalSelectDate = (
        <h2>{t('reservation.select-date')}</h2>
    )

    const specialForMallsSelectDate = (
        <h2 style={{color: dataForSpecialMalls.generalStyles.fontColor}}>
            <span style={{fontFamily: 'sf_pro_displayregular'}}>{t('reservation.special.select-date')} {t('reservation.special.your-visit')}</span> 
        </h2>
    )

    const isSpecialMall = !!dataForSpecialMalls[id];

    return (
        <div className="booking-page">
            <div className="container">
                <div className="frame">
                <header className="info-wrapper">
                        <div className="info">
                            <button
                                className="go-back"
                                style={{color: `${isSpecialMall ? dataForSpecialMalls[id].mainColor : '#3170f5'}`}}
                                onClick={() => {
                                    history.push(`/reservations/${id}/persons`)
                                }}>
                                <svg className="arrow-circle-left" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        fill={`${isSpecialMall ? dataForSpecialMalls[id].mainColor : '#3170f5'}`}
                                        d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z"/>
                                </svg>
                                <span>{t('reservation.go-back-persons')}</span>
                            </button>
                            <h1>{t('reservation.your-choose')} <strong>{currentPlace.title}</strong></h1>
                            <p>{t('reservation.persons')}: {persons}</p>
                        </div>
                        {isSpecialMall && logoForSpecialMalls}
                    </header>
                    <Steps step={3} />
                    <div className="row">

                        <div className="col-lg-4 col-md-5">
                            {isSpecialMall ? specialForMallsSelectDate : generalSelectDate}
                            <DatePicker
                                dayClassName={() => id }
                                selected={currentDate}
                                minDate={subDays(new Date(), 1)}
                                maxDate={addDays(new Date(), 6)}
                                onChange={date => setCurrentDate(date)}
                                locale={i18n.language === 'it' ? 'it-IT' : 'en-GB'}
                                inline
                            />
                            <div className="calendar-btn-container">
                                <button
                                    type="submit"
                                    className="continue-btn"
                                    style={{background: `${isSpecialMall ? dataForSpecialMalls[id].mainColor : '#3170f5'}`}}
                                    onClick={() => {
                                        history.push(`/reservations/${id}/timeline`)
                                    }}
                                >{t('reservation.continue')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ steps }) => (
    {
        currentDate: steps.currentDate,
        currentPlace: steps.currentPlace,
        persons: steps.persons
    }
);

export default withRouter(connect(mapStateToProps, {
    setCurrentDate,
    setCurrentPlace
})(Calendar));
