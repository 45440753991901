import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Terms ({history}) {
  const { t } = useTranslation();
  const mallId = localStorage.getItem('mallId');

  return (
    <div className="static-page">
      <div className="container">
        <div className="frame">
          <header>
            <button className="go-back" onClick={() => {history.push(`/${mallId}`)}}>
              {t('reservation.go-back')}
            </button>
            <h2>{t('contact.terms')}</h2>
          </header>
          <article>
    <p><strong>Termini e Condizioni &nbsp;di &ldquo;Mypriority.shop&rdquo; </strong></p>
    <p>Versione del &nbsp;18.05.2020<br /><br /></p>
    <p>I presenti Termini e Condizioni regolano l&rsquo;utilizzo della piattaforma &ldquo;<strong>Mypriority.shop</strong>&rdquo;</p>
    <p>I presenti Termini e Condizioni costituiscono un accordo vincolante tra ciascun utente di Mypriority.shop &nbsp;e</p>
    <p>New Target Web s.r.l. Via G. Carducci, 44 24127 Bergamo&nbsp;</p>
    <p>C.F./P.I.: IT03114730165 REA Bergamo n.350579 CS: 40.000 Eur (I.V.)</p>
    <p><strong> DEFINIZIONI </strong></p>
    <p>In aggiunta ai termini ed alle parole definiti altrove nei presenti Termini e Condizioni, ai fini dei medesimi si definiscono:</p>
    <p>1.1) &ldquo;<strong>Priority</strong>&rdquo;, un documento di legittimazione informatica che attribuisce all&rsquo;Utente che lo riceve il diritto di ottenere la priorità nell&rsquo;accesso ai locali dello Shop a cui il Priority si riferisce.</p>
    <p>1.2) &ldquo;<strong>Shop</strong>&rdquo;, qualsiasi soggetto direttamente o indirettamente affiliato a Mypriority.shop, per il cui accesso può essere utilizzato un Priority.</p>
    <p>1.3) &ldquo;<strong>Sito</strong>&rdquo;, Mypriority.shop  e&rsquo; un sito in gestione alla Società New Target Web s.r.l.</p>
    <p>&nbsp;</p>
    <p><strong> OGGETTO </strong></p>
    <p>2.1 Con l&rsquo;accettazione dei presenti Termini e Condizioni, l&rsquo;Utente è autorizzato ad utilizzare Mypriority.shop per prenotare uno o più <strong>Priority</strong> tramite il Sito, nei limiti e nel rispetto di quanto previsto dai presenti Termini.</p>
    <p>2.2 L&rsquo;Utente riconosce ed accetta che:</p>
    <p>2.2.1) il Priority concede il diritto esclusivo a riservare il proprio turno.</p>
    <p>2.2.2) ove non diversamente specificato, il Priority attribuisce in modo esclusivo dei diritti di priorità o di prenotazione ma non puo&rsquo; garantire all&rsquo;Utente l&rsquo;accesso ai locali dello Shop e/o di usufruire dei beni e servizi di quest&rsquo;ultimo, essendo tale possibilità delegata &nbsp;all&rsquo;autorizzazione dello Shop, anche in virtù del rispetto da parte dell&rsquo;Utente di tutti i Termini e Condizioni, i regolamenti e le policy applicati e praticati dallo Shop alla propria clientela o al proprio pubblico;</p>
    <p>2.2.3) l&rsquo;utilizzo del Biglietto e del Priority è soggetto agli specifici Termini e Condizioni, nonché alle specifiche limitazioni d&rsquo;uso, di volta in volta pubblicati su Mypriority.shop, anche mediante pop-up&nbsp; o via email, che l&rsquo;Utente accetta e si impegna a rispettare.</p>
    <p>2.4 L&rsquo;Utente si impegna a rispettare qualsiasi normativa primaria o secondaria applicabile all&rsquo;utilizzo dei Priority, e si impegna ad astenersi da ogni forma di utilizzo, diretto e/o indiretto, di Mypriority.shop che sia contrario alla legge, ai presenti Termini e Condizioni o lesivo di diritti di terzi.</p>
    <p><strong> DICHIARAZIONI E G ARANZIE DELL&rsquo;UTENTE </strong></p>
    <p>3.1 L&rsquo;Utente dichiara e garantisce:</p>
    <p><br />3.1.1) di avere i requisiti legali e morali &nbsp;per accettare i presenti Termini e Condizioni ed utilizzare Mypriority.shop ai sensi della normativa ad egli applicabile;<br />3.1.2) che i dati personali e le altre informazioni dichiarate alla Società in fase di registrazione sul Sito, o comunque nel contesto dell'utilizzo di Mypriority.shop, sono veritieri, corretti ed aggiornati;</p>
    <p>3.1.3) che l&rsquo;utilizzo di Mypriority.shop e dei Priority è consentito nella giurisdizione in cui l&rsquo;Utente è nato e/o si trova e/o è residente e/o domiciliato, e che l&rsquo;Utente non è sottoposto a restrizioni o provvedimenti interdittivi che proibiscano o limitino tale utilizzo da parte dell&rsquo;Utente in qualsiasi giurisdizione;</p>
    <p><strong> PRENOTAZIONE DEI PRIORITY</strong></p>
    <p>4.1 I Priority saranno resi disponibili per l&rsquo;acquisto di volta in volta dalla Società &nbsp;attraverso il Sito. Il Sito e/o il Priority stesso specificheranno &nbsp;tutte le informazioni relative al Priority nonché le eventuali condizioni speciali e le limitazioni d&rsquo;uso applicabili allo stesso, incluso il periodo di validità. Confermando la prenotazione di un Priority, l&rsquo;Utente dichiara di aver letto e compreso, ed espressamente accetta, tali condizioni speciali e limitazioni d&rsquo;uso.</p>
    <p>4.2 Avvenuta con successo la prenotazione di un Priority, la Società invierà all&rsquo;Utente un&rsquo;email di conferma, contenente un link ai presenti Termini e Condizioni, un riepilogo delle informazioni relative al Priority &nbsp;nonché un riepilogo delle condizioni speciali e delle limitazioni d&rsquo;uso applicabili al Priority.</p>
    <p>4.3 Il Priority acquistato verrà recapitato all&rsquo;Utente esclusivamente &nbsp;in modalità informatica, attraverso l&rsquo;invio, tramite il Sito, di un &ldquo;QR Code&rdquo;, ovvero un codice a barre bidimensionale o altra tipologia di codice che l&rsquo;Utente dovrà mostrare direttamente sul proprio dispositivo mobile allo Shop o al personale da questo incaricato per poter usufruire dei diritti incorporati nel Priority. In alternativa, oppure ove espressamente richiesto, l&rsquo;Utente potrà stampare il Priority ricevuto via email e presentarlo allo Shop in versione cartacea con una definizione sufficientemente elevata per consentire la lettura del relativo codice. Qualora l&rsquo;Utente non abbia potuto utilizzare un Priority entro il periodo di scadenza a causa dell&rsquo;impossibilità di leggere una stampa del codice in bassa o insufficiente definizione, l'Utente non avrà diritto ad alcun accesso prioritario.</p>
    <p>4.4 L&rsquo;Utente prende atto ed accetta che taluni dati ed informazioni relativi allo stesso potranno essere comunicati allo Shop e/o ai terzi che agiscono per conto dello Shop e/o a terzi fornitori di cui la Società si avvale per l&rsquo;erogazione del Servizio, al fine di poter consentire all&rsquo;Utente di beneficiare dei diritti incorporati nel Priority, anche alla luce della tipologia ed alla natura dei beni e servizi offerti dall&rsquo;Shop, nonché al fine di adempiere a possibili obblighi di legge.</p>
    <p><strong> UTILIZZO DEI PRIORITY </strong></p>
    <p>5.1 L&rsquo;Utente avrà il diritto di utilizzare il Priority esclusivamente presso lo specifico Shop e nello specifico punto di accesso indicato, nel rispetto e nei limiti delle informazioni riportate sul Sito in fase di prenotazione, ivi incluse le condizioni speciali e le eventuali limitazioni d&rsquo;uso applicabili al Priority, nonché delle condizioni e informazioni riportate sullo stesso.</p>
    <p>5.2 L&rsquo;Utente prende atto ed accetta che:</p>
    <p><br />5.2.1) il canale di priorità o preferenziale, a cui l&rsquo;Utente potrà accedere attraverso il Priority, è realizzato e gestito unicamente dallo Shop ed è soggetto ai Termini e Condizioni &nbsp;di utilizzo normalmente praticati dallo Shop verso&nbsp; il &nbsp;pubblico;<br />5.2.2) con riferimento ai Priority che attribuiscono diritti di priorità, la Società non dichiara né garantisce in alcun modo che il canale di priorità o preferenziale messo a disposizione dello Shop sia esclusivo per gli utenti di Mypriority.shop e, pertanto, l&rsquo;Utente accetta espressamente che egli potrebbe dover condividere tale canale preferenziale, oltre che con altri utenti di Mypriority.shop, anche con altri utenti titolari di diversi diritti di priorità acquisiti direttamente presso lo Shop o da soggetti terzi in maniera indipendente da Mypriority.shop;</p>
    <p>5.2.3) utilizzando un Priority che attribuisce diritti di priorità, l&rsquo;Utente non occuperà necessariamente la prima posizione, o altre specifiche posizioni, nel canale di priorità o preferenziale messo a disposizione dell'Shop, garantendo il Priority unicamente il diritto di accedere a tale canale di priorità o preferenziale, senza alcun ordine di preferenza;<br />5.2.4) utilizzando un Priority che attribuisce diritti di priorità, l&rsquo;Utente acquisisce unicamente il diritto di utilizzare il canale di priorità predisposto dallo Shop, senza alcuna garanzia che tale canale di priorità consentirà effettivamente un accesso più veloce rispetto ai normali canali di acceso non prioritari dello Shop.</p>
    <p>5.2.5) L&rsquo;Utente prende atto ed accetta che ciascun Priority potrà essere utilizzato solo entro e non oltre il periodo di validità indicato sul Sito al momento della prenotazione del Priority, ove previsto, e che, decorso tale termine senza che il Priority sia stato presentato allo Shop e da questo validato, il Priority dovrà ritenersi scaduto e non potrà essere più utilizzato. Pertanto, lo Shop potrà legittimamente rifiutare il riconoscimento di qualsiasi Priority scaduto, senza che la Società e/o lo Shop possano incorrere in alcuna responsabilità nei confronti dell&rsquo;Utente.<br />5.2.6) In caso di Priority che attribuiscono diritti di prenotazione, l&rsquo;Utente si impegna a presentarsi presso il luogo e il Punto indicati in fase di acquisto sul Sito o riportato sul&nbsp; Priority, entro e non oltre l&rsquo;orario di prenotazione..</p>
    <p><br />5.3 Quando disponibile &nbsp;su Mypriority.shop &nbsp;una funzione del tipo &ldquo;Invita un amico&rdquo;, l&rsquo;Utente potrà indicare un indirizzo di posta elettronica di un proprio amico, al fine di invitarlo a utilizzare&nbsp; Mypriority.shop. Inserito tale indirizzo di posta elettronica, la Società invierà all&rsquo;amico invitato un messaggio di posta elettronica di invito, contenente anche gli estremi identificativi (indirizzo di posta elettronica) dell&rsquo;Utente che ha effettuato l&rsquo;invito.<br />5.4 Utilizzando la funzione &ldquo;Invita un amico&rdquo;, o altre simili funzioni sul Sito, l&rsquo;Utente dichiara e garantisce: (i) di aver ottenuto il consenso preventivo, libero ed informato del terzo alla comunicazione del proprio indirizzo di posta elettronica alla Società per poter ricevere l&rsquo;invito; e (ii) di aver fornito al terzo una copia dell&rsquo;Informativa Privacy agli Utenti di Mypriority.shop, informando tale terzo che i propri dati personali saranno trattati dalla Società unicamente per inviare l&rsquo;invito. L&rsquo;Utente si impegna a sollevare e tenere indenne la Società da qualsiasi danno, costo, onere, spesa e responsabilità che la Società potrà sopportare o sostenere in conseguenza di azioni per il risarcimento del danno promosse dal terzo, sul presupposto che la Società abbia trattato i dati personali di tale terzo in assenza di un suo preventivo consenso.</p>
    <p><strong> RESPONSABILITÀ </strong></p>
    <p>6.1 L&rsquo;Utente prende atto che la Società non è il soggetto che fornisce i beni o servizi in relazione ai quali il Priority assegna &nbsp;priorità o diritti di prenotazione e che, per tale ragione, la Società non sarà in nessun modo responsabile nei confronti dell'Utente per la mancata o inesatta fornitura di tali beni e/o servizi, nonché per qualsiasi danno, costo, onere o spesa subita dall&rsquo;Utente per effetto della fornitura di tali beni o servizi dallo Shop e/o comunque in connessione ad essa, essendo gli Shop l&rsquo;unica controparte contrattuale dell'Utente con riferimento alla fornitura di tali beni o servizi. Inoltre, la Società è esclusa da qualsiasi responsabilità per &nbsp;inadempimento contrattuale ,totale e/o parziale &nbsp;degli obblighi assunti per causa di forza maggiore quali, a titolo esemplificativo e non esaustivo: atti dello Stato e della Pubblica Amministrazione, atti della Pubblica Autorità, limitazioni legali, incendi, alluvioni, esplosioni, mobilitazioni, sommosse, scioperi, turbative industriali, mancanza di materie prime, mancanza di elettricità, interruzione delle linee telefoniche, mancanza di oli combustibili ed altri (congiuntamente, le &ldquo;<strong>Cause di Forza Maggiore</strong>&rdquo;).</p>
    <p>6.2 Fatti salvi i casi di dolo o colpa grave, di morte o danni fisici all&rsquo;Utente nonché di danni derivanti dalla violazione da parte della Società di norme di ordine pubblico, e nella misura consentita dal D. Lgs. 206/2005 nel caso in cui l&rsquo;Utente agisca in veste di consumatore, le cui previsioni prevarranno in ogni caso su quanto previsto dal presente art. 6.2, (i) in nessun caso la Società sarà responsabile nei confronti dell&rsquo;Utente per lucro cessante, perdita di profitti o perdita di chance o altre simili forme di danno derivanti dalla violazione da parte della Società dei presenti Termini e Condizioni, e (ii) la responsabilità complessiva massima della Società nei confronti dell&rsquo;Utente derivante dall'inadempimento della Società dei presenti Termini o comunque in relazione ad essi non potrà in nessun caso eccedere il valore dei corrispettivi pagati dall&rsquo;Utente alla Società nei 12 (dodici) mesi precedenti il verificarsi l&rsquo;evento che ha cagionato il danno.</p>
    <p><strong> PRIVACY </strong></p>
    <p>10.1 I dati personali dell&rsquo;Utente verranno trattati dalla Societa&rsquo; per le finalità e con le modalità specificate nell&rsquo;Informativa Privacy agli Utenti di Mypriority.shop.<br /><br /></p>
    <p><strong> PROPRIETÀ INTELLETTUALE ED INDUSTRIALE </strong></p>
    <p>8.1 Il Sito (ed i suoi contenuti e la sua grafica), il sistema Mypriority.shop, il marchio della Società, il nome a dominio &ldquo;Mypriority.shop.com&rdquo; e tutti i diritti di proprietà intellettuale ed industriale ad essi relativi, sono in concessione esclusiva per l&rsquo;Italia alla Società o dei suoi licenzianti, sono ad essi riservati e non sono né saranno trasferiti in alcun caso all&rsquo;Utente.</p>
    <p>8.2 L&rsquo;Utente si impegna a non riprodurre, duplicare, copiare, vendere, trasferire, utilizzare a fini commerciali, modificare, decodificare, disassemblare, in tutto o in parte, il Sito e/o Mypriority.shop o creare opere da essi derivate o tentare di accedere al loro codice sorgente.</p>
    <p>8.3 L&rsquo;Utente riconosce ed accetta che il Sito pu&ograve; riportare automaticamente dati di diagnostica e scaricare automaticamente aggiornamenti al fine di aggiornare, migliorare e sviluppare ulteriormente il sistema Mypriority.shop, compresa la disponibilità di correzioni di bug, patch, funzioni avanzate, plug-in e nuove versioni. Il Sito e Mypriority.shop vengono forniti &ldquo;così come sono&rdquo;, ed essi potrebbero non essere disponibili o funzionare correttamente. La Società non fornisce alcuna garanzia sulla qualità, sulla disponibilità, sul funzionamento o sul rendimento del Sito o di Mypriority.shop, né di qualsiasi contenuto o servizio accessibile mediante essi.</p>
    <p><strong> DURATA, SOSPENSIONE E SOPRAVVIVENZA DI CLAUSOLE </strong></p>
    <p>9.1 I presenti Termini sono validi a tempo indeterminato, fermo restando che qualsiasi parte potrà recedere in qualsiasi momento dagli stessi, dandone comunicazione per iscritto all&rsquo;altra. Resta, tuttavia, inteso che, in caso di esercizio del diritto di recesso da parte dell&rsquo;Utente, il recesso non avrà effetto relativamente ai Priority già prenotati dall&rsquo;Utente prima della data di efficacia del recesso, che continueranno ad essere regolati dai Termini fino alla completa esecuzione delle obbligazioni delle Parti relative a tali Priority. La chiusura da parte dell&rsquo;Utente del proprio account sul Sito dovrà interpretarsi come esercizio del diritto di recesso dai presenti Termini da parte dell&rsquo;Utente. La chiusura da parte della Società del Sito, dovrà interpretarsi come esercizio del diritto di recesso dai presenti Termini da parte della Società.</p>
    <p>9.2 La Società si riserva, il diritto in qualsiasi momento di sospendere o interrompere definitivamente l'attività di Mypriority.shop e/o all'accesso al Sito, anche sospendendo o cancellando definitivamente l&rsquo;account dell&rsquo;Utente, senza alcun obbligo di preavviso all&rsquo;Utente, restando invariati i diritti già acquisiti dall&rsquo;Utente con riferimento ai Priority già acquistati prima della sospensione o della fine dell&rsquo;attività di Mypriority.shop. L&rsquo;Utente riconosce ed accetta che la Società non garantisce in alcun modo che Mypriority.shop e l'accesso al Sito saranno disponibili.<br /><br /></p>
    <p><strong> RISOLUZIONE </strong></p>
    <p>10.1 la Società &nbsp;Ai sensi dell&rsquo;art. 1456 cod. civ., potrà risolvere con effetto immediato i presenti Termini e Condizioni, inviando una comunicazione scritta all&rsquo;Utente, qualora l&rsquo;Utente abbia violato una o più delle previsioni dei termini. Resta in ogni caso impregiudicato il diritto della Società al risarcimento di ogni eventuale danno.</p>
    <p><strong> LEGGE APPLICABILE E FORO COMPETENTE </strong></p>
    <p>11.1 I presenti Termini sono interamente disciplinati dalla legge italiana.<br />11.2 Qualsiasi controversia che dovesse sorgere tra le Parti in relazione alla validità, interpretazione, esecuzione e risoluzione dei presenti Termini e/o comunque in connessione con i presenti Termini sarà di esclusiva competenza del Foro di Bergamo, &nbsp;con esclusione di ogni altro, anche concorrente o alternativo. Tuttavia, qualora l&rsquo;Utente agisca in veste di &ldquo;consumatore&rdquo; ai sensi della Direttiva 2011/83/UE, il Foro competente sarà quello del luogo in cui l&rsquo;Utente ha la residenza o il proprio domicilio elettivo nel territorio italiano. L&rsquo;Utente che agisca in veste di consumatore si impegna a prendere visione di quanto previsto dal Titolo III (Modalità Contrattuali), Capo I (Dei diritti dei consumatori nei contratti) del D. Lgs. 206/2005.<br />11.3 La Società informa l&rsquo;Utente che la Commissione Europea fornisce una piattaforma per la risoluzione delle controversie online, a cui è possibile accedere tramite questo link: http://ec.europa.eu/consumers/odr/.</p>
        <p><strong> MODIFICHE </strong></p>
    <p>12.1 La Società si riserva il diritto di aggiornare o modificare in qualsiasi momento i presenti Termini. In tal caso, la Società informerà gli Utenti delle modifiche effettuate direttamente sul Sito oppure mediante comunicazione all&rsquo;Utente all&rsquo;indirizzo email indicato in fase di registrazione a Mypriority.shop. Accettando le modifiche ai Termini e Condizioni notificate sul Sito oppure continuando ad utilizzare Mypriority.shop dopo aver ricevuto l'email di notifica delle modifiche ai Termini e Condizioni, l&rsquo;Utente dichiara di aver accettato tali modifiche. In ogni caso, le modifiche ai Termini e Condizioni avranno effetto esclusivamente con riferimento ai Priority acquistati dall&rsquo;Utente successivamente alla data di notifica di tali modifiche. Resta fermo il diritto per l&rsquo;Utente di recedere dai presenti Termini e Condizioni ai sensi del precedente art. 9.1, qualora l&rsquo;Utente non intenda accettare le modifiche ai Termini e Condizioni.</p>
    <p><strong> CESSIONE E SUBAPPALTO </strong></p>
    <p>13.1 L&rsquo;Utente prende atto ed accetta che la Società potrà cedere in tutto o in parte i presenti Termini e Condizioni a terzi, nonché potrà subappaltare a terzi in tutto o in parte l'esecuzione dei presenti Termini e Condizioni.</p>
    <p><strong> COMUNICAZIONI E RECLAMI </strong></p>
    <p>14.1 Qualunque comunicazione da entrambe le parti &nbsp;ai sensi dei presenti Termini e Condizioni dovrà essere inviata a mezzo fax o lettera raccomandata a.r. ai seguenti indirizzi:</p>
    <p>New Target Web s.r.l. Via G. Carducci, 44 24127 Bergamo&nbsp;</p>
    <p>Fax: (+39) 035 270395</p>
    <p>La Società darà riscontro agli eventuali reclami presentati dall&rsquo;Utente entro 10 (dieci) giorni lavorativi.</p>
    </article>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Terms);