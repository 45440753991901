import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import client from '../client';

import { getItalianDate, getTimeFromDate } from '../utils';

import logo from '../assets/images/logo.png';
import appleWallet from '../assets/images/apple-wallet.png';
import googleWallet from '../assets/images/google-wallet.png';

function Check({location}) {
  const { t } = useTranslation();
  const id = location.search.split('?id=').pop();
  const [errors, setErrors] = useState();
  const [data, setData] = useState();
  const [date, setDate] = useState();
  const [hours, setHours] = useState();
  
  useEffect(() => {
    id && client.getData(`/reservations?id=${id}`)
      .then((data) => {
        setData(data);
        setDate(getItalianDate(data.date_from));
        setHours({
          from: getTimeFromDate(data.date_from),
          to: getTimeFromDate(data.date_to),
        })
      }).catch(errors => {
        setErrors(errors);
      });
  }, [id]);

  return (
    <div className="vh-100">
      <div className="text-center">
        {data && !errors && 
          <table border="0" cellPadding="0" cellSpacing="0" width="600" style={{ margin: "0 auto", "fontFamily": "Arial, Helvetica, sans-serif", "fontSize": "15px", "lineHeight": "23px" }}>
            <tbody>
              <tr>
                <td style={{ "padding": "10px 0 30px 0" }}>
                  <table align="center" border="0" cellPadding="0" cellSpacing="0" width="600" style={{ "border": "1px solid #cccccc", "borderCollapse": "collapse" }}>
                    <tbody>
                      <tr>
                        <td align="left" style={{ "backgroundColor": "#002857", "padding": "18px 24px" }}>
                          <img src={logo} alt="mypriority.shop" width="181" height="24" style={{ "display": "block" }} />
                        </td>
                      </tr>
                      <tr>
                        <td align="left" style={{ "color": "#000", "fontSize": "15px", "padding": "24px" }}>
                          <p style={{ "margin": "0 0 15px" }}>{t('confirmation.dear-customer')},</p>
                          {hours && <p style={{ "margin": "0 0 15px" }}>{t('confirmation.we-confirm')} <strong><span className="text-capitalize">{date}</span> {hours.from} {t('confirmation.hour-to')} {hours.to}</strong> {t('confirmation.in-the-store')} <strong>{data.place_title}</strong>.</p>}
                          <p style={{ "margin": "0 0 15px" }}><strong>{t('confirmation.people-number')}</strong>: {data.visitors_number}</p>
                        </td>
                      </tr>
                      <tr>
                        <td align="left" style={{ "color": "#000", "fontSize": "15px", "padding": "24px" }}>
                          <p style={{ "margin": "0 0 25px" }}>{t('confirmation.we-send-qr')}.</p>
                          <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ "margin": "0 0 40px", "fontFamily": "Arial, Helvetica, sans-serif; font-size: 15px" }}>
                            <tbody>
                              <tr>
                                <td width="200" style={{ "textAlign": "center", "verticalAlign": "top", "paddingRight": "25px", "borderRight": "1px solid #d8d8d8" }}>
                                  <img src={data.qr} alt="qr" width="200" style={{ "display": "block" }} />
                                </td>
                                <td style={{ "verticalAlign": "top", "paddingLeft": "25px" }}>
                                  <p style={{ "margin": "0 0 2px" }}><strong>{t('confirmation.please-save-qr')}</strong></p>
                                  <p style={{ "fontSize": "13px", "margin": "0 0 20px" }}>{t('confirmation.you-need-them')}.</p>
                                  <p style={{ "margin": "0 0 8px" }}>
                                    <a href={data.apple_wallet} style={{ "borderRadius": "3px", "textDecoration": "none", "display": "block", "background": "#000", "textAlign": "center", "width": "210px", "fontSize": "14px", "lineHeight": "40px", "color": "#fff" }}>
                                      <img src={appleWallet} alt="apple wallet" width="24" height="24" style={{ "margin": "-1px 5px 0 0", "verticalAlign": "middle" }} /> {t('confirmation.save-apple')}
                                    </a>
                                  </p>
                                  <p style={{ "margin": "0 0 8px" }}>
                                    <a href={data.google_wallet} style={{ "borderRadius": "3px", "textDecoration": "none", "display": "block", "background": "#fff", "textAlign": "center", "width": "208px", "border": "1px solid #ccc", "fontSize": "14px", "lineHeight": "38px", "color": "#000" }}>
                                      <img src={googleWallet} alt="apple wallet" width="24" height="24" style={{ "margin": "-1px 5px 0 0", "verticalAlign": "middle" }} /> {t('confirmation.save-google')}
                                    </a>
                                  </p>
                                  <p style={{ "margin": "0" }}>
                                    <a href={data.qr} style={{ "borderRadius": "3px", "textDecoration": "none", "display": "block", "background": "#fff", "textAlign": "center", "width": "208px", "border": "1px solid #ccc", "fontSize": "14px", "lineHeight": "38px", "color": "#000" }}>
                                      {t('confirmation.download-qr')}
                                    </a>
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>

                          <p style={{ "margin": "0 0 15px" }}>{t('confirmation.cancel-recording')} <a href={`/reservations?id=${id}`} style={{ "color": "#3170f5", "textDecoration": "none" }}>{t('confirmation.click-here')}</a>.</p>
                          <p style={{ "margin": "0 0 35px" }}>{t('confirmation.command-together')} <a href="/" style={{ "color": "#3170f5", "textDecoration": "none" }}>Mypriority.shop</a> {t('confirmation.with-center')} {data.place_title}.</p>
                          <ul style={{ "margin": "0", "padding": "0", "listStyle": "none" }}>
                            <li style={{ "display": "inline", "margin": "0 15px 0 0" }}>
                              <a href="/privacy" style={{ "color": "#888", "textDecoration": "none" }}>{t('contact.privacy')}</a>
                            </li>
                            <li style={{ "display": "inline" }}>
                              <a href="/terms" style={{ "color": "#888", "textDecoration": "none" }}>{t('contact.terms')}</a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        }
        {errors && <span className="display-4 d-block">{t('general.error')}</span>}
      </div>
    </div>
  );
};

export default Check;