import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './App.scss';
import Wrapper from './containers/Wrapper';
import Cookies from './static/Cookies';
import Terms from './static/Terms';
import Privacy from './static/Privacy';
import Timeslots from './steps/Timeslots';
import Reservation from './steps/Reservation';
import RetrieveReservation from './steps/RetrieveReservation';
import Page404 from './containers/404';
import Delete from './containers/Delete';
import Confirmation from './containers/Confirmation';
import Check from './containers/Check';
import Persons from "./steps/Persons";
import Calendar from "./steps/Calendar";
import Timeline from "./steps/Timeline";

function App() {
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/">
            <Wrapper />
          </Route>
          <Route
            exact
            path="/reservations"
            render={(props) => <Delete {...props} />}
          />
          <Route
            exact
            path="/reservations/confirmation"
            render={(props) => <Confirmation {...props} />}
          />
          <Route
            exact
            path="/reservations/check"
            render={(props) => <Check {...props} />}
          />
          <Route exact path="/:id">
            <Wrapper />
          </Route>
          <Route path="/reservations/:id/persons" component={Persons} />
          <Route path="/reservations/:id/calendar" component={Calendar} />
          <Route path="/reservations/:id/timeline" component={Timeline} />
          <Route exact path="/:id/timeslots">
            <Timeslots />
          </Route>
          <Route exact path="/:id/book">
            <Reservation />
          </Route>
          <Route exact path="/:id/reservations/restore">
            <RetrieveReservation />
          </Route>
          <Route exact path="/:id/cookies">
            <Cookies />
          </Route>
          <Route path="/:id/terms">
            <Terms />
          </Route>
          <Route exact path="/:id/privacy">
            <Privacy />
          </Route>
          <Route path="">
            <Page404 />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
