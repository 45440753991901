import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';

import { validateEmail } from '../utils';
import client from '../client';
import dataForSpecialMalls from '../data/DataForSpecialMalls';

function RetrieveReservation ({history}) {
  const { t } = useTranslation();
  const [sendStatus, setSendStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [email, setEmail] = useState();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const mallId = localStorage.getItem('mallId');

  function retrieveProceed(token) {
    setLoading(true);
    const data = {
      email,
      captcha_token: token
    }

    client.updateData(`/reservations/restore`, data)
      .then(({ errors, success }) => {
        if (success && !errors) {
          setSendStatus(true);
          setLoading(false);
        }
        else {
          setSendStatus(false);
          setErrors(errors);
          setLoading(false);
        }
      });
  }

  const onEmailSubmit = async (e) => {
    e.preventDefault();
    const token = await executeRecaptcha('submit');
    retrieveProceed(token);
  }

  const isSpecialMall = !!dataForSpecialMalls[mallId];

  return (
    <div className="booking-page">
      <div className="border-row">
        <div className="container">
          <div className="frame">
            <header>
              <button
                className="go-back"
                style={{color: `${isSpecialMall ? dataForSpecialMalls[mallId].mainColor : '#3170f5'}`}}
                onClick={() => history.push(`/${mallId}`)}
              >
                <svg className="arrow-circle-left" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                      fill={`${isSpecialMall ? dataForSpecialMalls[mallId].mainColor : '#3170f5'}`}
                      d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z"
                  />
                </svg>
                <span>{t('reservation.go-back')}</span>
              </button>
            </header>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col">
            <form className="booking-form">
              <label>{t('reservation.reset-label')}</label>
              <div className="input-row">
                <input
                  className="booking-email-input"
                  type="text" 
                  placeholder={t('reservation.email-text')}
                  onChange={(e) => setEmail(e.target.value)} 
                  value={email || ""} 
                />
                <button 
                  disabled={sendStatus || !validateEmail(email)}
                  style={{background: `${isSpecialMall ? dataForSpecialMalls[mallId].mainColor : '#3170f5'}`}}
                  className={sendStatus ? "sent" : undefined} 
                  data-sitekey="6LeSm_oUAAAAAPOFigAGjcT0nFKSxMVvh76CY6iu" 
                  data-callback='onSubmit' 
                  data-action='submit'
                  onClick={onEmailSubmit}
                >
                  {sendStatus ? (<i className="ico-ok">{t('reservation.sended')}</i>) : t('reservation.send')}
                </button>
              </div>
              <div>
                {t("reservation.receive-bookings-again")}
              </div>
              {loading && <p>Loading...</p>}
              {sendStatus &&
                <>
                  <p className="resend">{t('reservation.dont-get')} — <span>{t('reservation.resend')}</span></p>
                  <div className="message">
                    <p>{t('reservation.please')} <strong>{t('reservation.check-email')}</strong></p>
                    <p>{t('reservation.show-qr-code')}</p>
                  </div>
                </>
              }
              {errors && errors.length && errors.map(({ key, messages }, index) =>
                <div key={index} className="alert alert-danger mt-3 mb-2 rounded" role="alert">
                  {messages && messages.map((message, index) =>
                    <p key={index}>{t('errors.error')} ({key}): {message}</p>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(RetrieveReservation);