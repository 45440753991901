import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import enGb from 'date-fns/locale/en-GB';
import itIt from 'date-fns/locale/it';
import { formatDate as parseDate, chunkArray } from '../utils';
import client from '../client';
import { Steps } from '../steps/Steps';

function Timeslots({ history, location }) {
  registerLocale('it-IT', itIt);
  registerLocale('en-GB', enGb);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [persons, setPersons] = useState(1);
  const [slotsList, setSlotsList] = useState([]);
  const [currentPlace, setCurrentPlace] = useState();
  const [currentTime, setCurrentTime] = useState();

  const mallId = localStorage.getItem('mallId');

  const validCondition = currentDate && currentTime && currentPlace?.id && persons;
  const subDays = (d) => d.setDate(d.getDate());

  function formatDate(d) {
    const day = d.getDate();
    const month = d.toLocaleString(i18n.language === 'it' ? 'it-IT' : 'en-GB', { month: 'long' });

    return `${day} ${month}`;
  }

  function submitReservation() {
    const data = {
      date: currentDate,
      parsedDate: parseDate(currentDate),
      persons,
      place: currentPlace,
      time: currentTime,
    }

    history.push(`/${mallId}/book`, data);
    console.log(history)
  }

  function getSlotsList(date) {
    setLoading(true);
    currentPlace &&
      client.getData(`/places/${currentPlace.id}/reservations?date=${parseDate(date)}&visitors_number=${persons}`)
        .then(({ slots, errors, success }) => {
          if (success && !errors) {
            setSlotsList(chunkArray(slots, 18));
            setLoading(false);
          }
          else {
            setErrors(errors);
          }
        });
  }

  useEffect(() => {
    getSlotsList(currentDate);
  }, [currentPlace, currentDate, persons]);

  useEffect(() => {
    setCurrentPlace(location.state);
  }, [location.state]);

  return (
    <div className="booking-page">
      <div className="container">
        <div className="frame">
          <header>
            <button className="go-back" onClick={() => { history.push(`/${mallId}`) }}>
              {t('reservation.go-back-place')}
            </button>
            <div className="row header-row">
              {currentPlace &&
                <div className="col-md-7">
                  <h1>{t('reservation.your-choose')} <strong>{currentPlace.name}</strong></h1>
                  <p>{persons} {t('reservation.persons')}, {formatDate(currentDate)}, {currentTime || t('general.select-time')}</p>
                </div>
              }
              <div className="reservation-frame col-md-5 text-right-sm">
                <button
                  disabled={!validCondition}
                  type="submit"
                  className="button"
                  onClick={() => submitReservation()}
                >{t('reservation.finish')}</button>
              </div>
            </div>
          </header>
          <Steps step={2} />
          <main>
            <div className="row">
              <div className="col">
                <h2>1. {t('reservation.how-many')} {currentPlace.max_persons && <span>(Max: {currentPlace.max_persons})</span>}?</h2>
                <div className="number-select">
                  <button
                    className="minus"
                    onClick={() => persons !== 1 && setPersons(persons - 1)}
                  >-</button>
                  <input type="text" value={`${persons} persons`} readOnly={true} />
                  <button
                    className="plus"
                    onClick={() => setPersons(persons + 1)}
                  >+</button>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-5">
                <h2>2. {t('reservation.select-date')}</h2>
                <DatePicker
                  selected={currentDate}
                  minDate={subDays(new Date(), 1)}
                  onChange={date => setCurrentDate(date)}
                  locale={i18n.language === 'it' ? 'it-IT' : 'en-GB'}
                  inline
                />
              </div>
              <div className="col-lg-8 col-md-7">
                <h2>3. {t('reservation.select-time')}</h2>
                {errors && errors.length && errors.map(({ key, messages }, index) =>
                  <div key={index} className="alert alert-danger mt-3 mb-2 rounded" role="alert">
                    {messages && messages.map((message, index) =>
                      <p key={index}>{t('errors.error')} ({key}): {message}</p>
                    )}
                  </div>
                )}
                {loading ? <p>Loading...</p> : (
                  <div className="row">
                    {slotsList && slotsList.map((column, index) => (
                      <div key={index} className="col-md-4 col-sm-3">
                        <div className="available-slots">
                          {column && column.map((item, index) => (
                            <button
                              data-active={currentTime === `${item.from} - ${item.to}`}
                              key={index}
                              className="slot"
                              onClick={() => setCurrentTime(`${item.from} - ${item.to}`)}
                            >{item.from} - {item.to}
                            </button>
                          ))}
                        </div>
                      </div>
                    ))}
                    {!slotsList.length &&
                      <div className="col">
                        <p>{t('reservation.empty-slots')}</p>
                      </div>
                    }
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Timeslots);
