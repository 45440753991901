export const api_url = 'https://api.mypriority.shop/api/v1';
const isDevelop = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const client = {
  postData: async function postData(url = '', data = {}) {
    const targetUrl = isDevelop ? url : `${api_url}${url}`;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const response = await fetch(targetUrl, {
      method: 'POST',
      cache: 'no-cache',
      headers,
      body: JSON.stringify(data)
    });
    return await response.json();
  },

  updateData: async function updateData(url = '', data = {}) {
    const targetUrl = isDevelop ? url : `${api_url}${url}`;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const response = await fetch(targetUrl, {
      method: 'PUT',
      cache: 'no-cache',
      headers,
      body: JSON.stringify(data)
    });
    return await response.json();
  },

  getData: async function getData(url = '') {
    const targetUrl = isDevelop ? url : `${api_url}${url}`;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const response = await fetch(targetUrl, {
      method: 'GET',
      cache: 'no-cache',
      headers
    });
    return await response.json();
  },

  deleteData: async function deleteData(url = '') {
    const targetUrl = isDevelop ? url : `${api_url}${url}`;
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const response = await fetch(targetUrl, {
      method: 'DELETE',
      cache: 'no-cache',
      headers
    });
    return await response.json();
  }
}


export default client;