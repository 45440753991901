import * as action from '../constants';

const INITIAL_STATE = {
    places: [],
    categories: [],
    malls: [],
    geolocation: {},
    errors: []
};

export const main = (state=INITIAL_STATE, actions) => {
    switch (actions.type) {
        case action.GET_PLACES_SUCCESS: {
            return {
                ...state,
                places: actions.payload
            }
        }

        case action.GET_CATEGORIES_SUCCESS: {
            return {
                ...state,
                categories: actions.payload
            }
        }

        case action.GET_MALLS_SUCCESS: {
            return {
                ...state,
                malls: actions.payload
            }
        }

        case action.GET_GEOLACATION_SUCCESS: {
            return {
                ...state,
                geolocation: actions.payload
            }
        }

        default: 
            return state
    }
}