import * as actions from '../constants';

export const addPerson = () => ({ type: actions.ADD_PERSON });
export const removePerson = () => ({ type: actions.REMOVE_PERSON });
export const setCurrentPlace = (state) => ({ type: actions.SET_CURRENT_PLACE, payload: state });
export const setCurrentDate = (date) => ({ type: actions.SET_CURRENT_DATE, payload: date });
export const setCurrentTime = (time) => ({ type: actions.SET_CURRENT_TIME, payload: time });
export const getSlotList = () => ({ type: actions.TIME_SLOTS_LIST_LOAD });
export const setSlotsList = (slotsList) => ({ type: actions.TIME_SLOTS_LIST_LOAD_SUCCESS, payload: slotsList });
export const setStepsErrors = (errors) => ({ type: actions.TIME_SLOTS_LIST_LOAD_FAILURE, payload: errors });
export const setPlaces = (places) => ({ type: actions.GET_PLACES_SUCCESS, payload: places });
export const setCategories = (categories) => ({ type: actions.GET_CATEGORIES_SUCCESS, payload: categories });
export const setMalls = (malls) => ({ type: actions.GET_MALLS_SUCCESS, payload: malls });
export const setGeolocation = (position) => ({ type: actions.GET_GEOLACATION_SUCCESS, payload: position });