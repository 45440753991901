export const ADD_PERSON = 'ADD_PERSON';
export const REMOVE_PERSON = 'REMOVE_PERSON';
export const SET_CURRENT_PLACE = 'SET_CURRENT_PLACE';
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE';
export const SET_CURRENT_TIME = 'SET_CURRENT_TIME';

//API
export const TIME_SLOTS_LIST_LOAD = 'TIME_SLOTS_LIST_LOAD';
export const TIME_SLOTS_LIST_LOAD_SUCCESS = 'TIME_SLOTS_LIST_LOAD_SUCCESS';
export const TIME_SLOTS_LIST_LOAD_FAILURE = 'TIME_SLOTS_LIST_LOAD_FAILURE';
export const GET_PLACES = 'GET_PLACES';
export const GET_PLACES_SUCCESS = 'GET_PLACES_SUCCESS';
export const GET_PLACES_FAILURE = 'GET_PLACES_FAILURE';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_MALLS_SUCCESS = 'GET_MALLS_SUCCESS';
export const GET_GEOLACATION_SUCCESS = 'GET_GEOLACATION_SUCCESS';