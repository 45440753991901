export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export function getItalianDate(date) {
  return new Intl.DateTimeFormat('it-IT', {
    month: 'long', day: 'numeric'
  }).format(new Date(date));
}

export function getTimeFromDate(date) {
  return new Intl.DateTimeFormat('it-IT', {
    hour: 'numeric', minute: 'numeric'
  }).format(new Date(date));
}


export function chunkArray(arr, chunkSize) {
  var chunked = [];
  for (var i = 0; i < arr.length; i += chunkSize)
    chunked.push(arr.slice(i, i + chunkSize));
  return chunked;
}