import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Page404() {
  const { t } = useTranslation();
  return (
    <div className="vh-100 page-wrap d-flex flex-row align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <span className="display-4 d-block">404</span>
            <div className="mb-4 lead">{t('general.page-not-found')}</div>
            <a href="https://www.mypriority.shop" className="btn btn-link">{t('general.back-to-homepage')}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Page404);