import client from '../../client';
import { formatDate as parseDate, chunkArray } from '../../utils';

import { 
  getSlotList, 
  setSlotsList, 
  setStepsErrors,
  setPlaces,
  setCategories,
  setMalls,
  setGeolocation
} from '../actionsCreators';

export const getTimeSlots = (id, date, persons) => {
    return dispatch => {
        dispatch(getSlotList());

        client.getData(`/places/${id}/reservations?date=${parseDate(date)}&visitors_number=${persons}`)
        .then(({ slots, errors, success }) => {
          if (success && !errors) {
            dispatch(setSlotsList(chunkArray(slots, 18)));
          }
          else {
            dispatch(setStepsErrors(errors));
          }
        });
    }
};

export const getMainData = () => {
  return dispatch => {
    const apiUrls = ['/place_categories'];
    const requests = apiUrls.map(url => client.getData(url));

    Promise.all(requests)
      .then(([categories]) => {
        const { place_categories } = categories;
        
        dispatch(setCategories(place_categories));

      })

  }
}