import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Cookies ({history}) {
  const { t } = useTranslation();
  const mallId = localStorage.getItem('mallId');

  return (
    <div className="static-page">
      <div className="container">
        <div className="frame">
          <header>
            <button className="go-back" onClick={() => {history.push(`/${mallId}`)}}>
              {t('reservation.go-back')}
            </button>
            <h2>{t('contact.cookies')}</h2>
          </header>
          <article>
            <p>I Cookie sono costituiti da porzioni di codice installate all'interno del browser che assistono il Titolare nell’erogazione del Servizio in base alle finalità descritte. Alcune delle finalità di installazione dei Cookie potrebbero, inoltre, necessitare del consenso dell'Utente.</p>
            <p>Quando l’installazione di Cookies avviene sulla base del consenso, tale consenso può essere revocato liberamente in ogni momento seguendo le istruzioni contenute in questo documento.</p>
            <p>Cookie tecnici e di statistica aggregata</p>
            <p>Attività strettamente necessarie al funzionamento</p>
            <p>Questa Applicazione utilizza Cookie per salvare la sessione dell'Utente e per svolgere altre attività strettamente necessarie al funzionamento di questa Applicazione, ad esempio in relazione alla distribuzione del traffico.</p>
            <p>Attività di salvataggio delle preferenze, ottimizzazione e statistica</p>
            <p>Questa Applicazione utilizza Cookie per salvare le preferenze di navigazione ed ottimizzare l'esperienza di navigazione dell'Utente. Fra questi Cookie rientrano, ad esempio, quelli per impostare la lingua e la valuta o per la gestione di statistiche da parte del Titolare del sito.</p>
            <p>Altre tipologie di Cookie o strumenti terzi che potrebbero installarne</p>
            <p>Alcuni dei servizi elencati di seguito raccolgono statistiche in forma aggregata ed anonima e potrebbero non richiedere il consenso dell'Utente o potrebbero essere gestiti direttamente dal Titolare – a seconda di quanto descritto – senza l'ausilio di terzi.</p>
            <p>Qualora fra gli strumenti indicati in seguito fossero presenti servizi gestiti da terzi, questi potrebbero – in aggiunta a quanto specificato ed anche all’insaputa del Titolare – compiere attività di tracciamento dell’Utente. Come posso esprimere il consenso all'installazione di Cookie?</p>
            <p>In aggiunta a quanto indicato in questo documento, l'Utente può gestire le preferenze relative ai Cookie direttamente all'interno del proprio browser ed impedire – ad esempio – che terze parti possano installarne. Tramite le preferenze del browser è inoltre possibile eliminare i Cookie installati in passato, incluso il Cookie in cui venga eventualmente salvato il consenso all'installazione di Cookie da parte di questo sito. L'Utente può trovare informazioni su come gestire i Cookie con alcuni dei browser più diffusi ad esempio ai seguenti indirizzi: Google Chrome, Mozilla Firefox, Apple Safari e Microsoft Internet Explorer. Con riferimento a Cookie installati da terze parti, l'Utente può inoltre gestire le proprie impostazioni e revocare il consenso visitando il relativo link di opt out (qualora disponibile), utilizzando gli strumenti descritti nella privacy policy della terza parte o contattando direttamente la stessa.</p>
            <p>Fermo restando quanto precede, l’Utente può avvalersi delle informazioni fornite da EDAA (UE), Network Advertising Initiative(USA) e Digital Advertising Alliance (USA), DAAC (Canada), DDAI (Giappone) o altri servizi analoghi. Con questi servizi è possibile gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle informazioni fornite dal presente documento.</p>
            <p>Titolare del Trattamento dei Dati</p>
            <p>New Target Web s.r.l. Via G. Carducci, 44 24127 Bergamo C.F./P.I.: IT03114730165 REA Bergamo n.350579 CS: 40.000 Eur (I.V.)</p>
            <p>Indirizzo email del Titolare: privacy@ntnext.it</p>
            <p>Dal momento che l'installazione di Cookie e di altri sistemi di tracciamento operata da terze parti tramite i servizi utilizzati all'interno di questa Applicazione non può essere tecnicamente controllata dal Titolare, ogni riferimento specifico a Cookie e sistemi di tracciamento installati da terze parti è da considerarsi indicativo. Per ottenere informazioni complete, l’Utente è invitato a consultare la privacy policy degli eventuali servizi terzi elencati in questo documento.</p>
            <p>Vista l'oggettiva complessità di identificazione delle tecnologie basate sui Cookie l'Utente è invitato a contattare il Titolare qualora volesse ricevere qualunque approfondimento relativo all'utilizzo dei Cookie stessi tramite questa Applicazione.</p>
            Quando l’installazione di Cookies avviene sulla base del consenso, tale consenso può essere revocato liberamente in ogni momento seguendo le istruzioni contenute in questo documento.
    Cookie tecnici e di statistica aggregata
•	Attività strettamente necessarie al funzionamento
Questa Applicazione utilizza Cookie per salvare la sessione dell'Utente e per svolgere altre attività strettamente necessarie al funzionamento di questa Applicazione, ad esempio in relazione alla distribuzione del traffico.
•	Attività di salvataggio delle preferenze, ottimizzazione e statistica
Questa Applicazione utilizza Cookie per salvare le preferenze di navigazione ed ottimizzare l'esperienza di navigazione dell'Utente. Fra questi Cookie rientrano, ad esempio, quelli per impostare la lingua e la valuta o per la gestione di statistiche da parte del Titolare del sito.
•	Altre tipologie di Cookie o strumenti terzi che potrebbero installarne
Alcuni dei servizi elencati di seguito raccolgono statistiche in forma aggregata ed anonima e potrebbero non richiedere il consenso dell'Utente o potrebbero essere gestiti direttamente dal Titolare – a seconda di quanto descritto – senza l'ausilio di terzi.
    Qualora fra gli strumenti indicati in seguito fossero presenti servizi gestiti da terzi, questi potrebbero – in aggiunta a quanto specificato ed anche all’insaputa del Titolare – compiere attività di tracciamento dell’Utente.
    Come posso esprimere il consenso all'installazione di Cookie?
In aggiunta a quanto indicato in questo documento, l'Utente può gestire le preferenze relative ai Cookie direttamente all'interno del proprio browser ed impedire – ad esempio – che terze parti possano installarne. Tramite le preferenze del browser è inoltre possibile eliminare i Cookie installati in passato, incluso il Cookie in cui venga eventualmente salvato il consenso all'installazione di Cookie da parte di questo sito. L'Utente può trovare informazioni su come gestire i Cookie con alcuni dei browser più diffusi ad esempio ai seguenti indirizzi: Google Chrome, Mozilla Firefox, Apple Safari e Microsoft Internet Explorer.
    Con riferimento a Cookie installati da terze parti, l'Utente può inoltre gestire le proprie impostazioni e revocare il consenso visitando il relativo link di opt out (qualora disponibile), utilizzando gli strumenti descritti nella privacy policy della terza parte o contattando direttamente la stessa.
Fermo restando quanto precede, l’Utente può avvalersi delle informazioni fornite da EDAA (UE), Network Advertising Initiative(USA) e Digital Advertising Alliance (USA), DAAC (Canada), DDAI (Giappone) o altri servizi analoghi. Con questi servizi è possibile gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle informazioni fornite dal presente documento.
    Titolare del Trattamento dei Dati
New Target Web s.r.l. Via G. Carducci, 44 24127 Bergamo
C.F./P.I.: IT03114730165 REA Bergamo n.350579 CS: 40.000 Eur (I.V.)

Indirizzo email del Titolare: privacy@ntnext.it

Dal momento che l'installazione di Cookie e di altri sistemi di tracciamento operata da terze parti tramite i servizi utilizzati all'interno di questa Applicazione non può essere tecnicamente controllata dal Titolare, ogni riferimento specifico a Cookie e sistemi di tracciamento installati da terze parti è da considerarsi indicativo. Per ottenere informazioni complete, l’Utente è invitato a consultare la privacy policy degli eventuali servizi terzi elencati in questo documento.
    Vista l'oggettiva complessità di identificazione delle tecnologie basate sui Cookie l'Utente è invitato a contattare il Titolare qualora volesse ricevere qualunque approfondimento relativo all'utilizzo dei Cookie stessi tramite questa Applicazione.
          </article>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Cookies);
