import React from 'react';
import { useTranslation } from 'react-i18next';

import { Step } from './Step/Step';
import './Steps.scss';
import dataForSpecialMalls from '../data/DataForSpecialMalls';

export const Steps = ({ step }) => {
    const { t } = useTranslation();
    const titlesOfSteps = {
        '1': t('steps.choose-shop'),
        '2': t('steps.choose-person'),
        '3': t('steps.choose-date'),
        '4': t('steps.choose-time'),
        '5': t('steps.insert-email'),
    };
    const numberOfSteps = Object.keys(titlesOfSteps).length;
    const steps = [];
    const mallId = localStorage.getItem('mallId');
    
    for (let currentStep = 1; currentStep <= numberOfSteps; currentStep++) {
        steps.push(<Step
                        activeStep={step}
                        currentStep={currentStep}
                        title={titlesOfSteps[currentStep]}
                        backgroundOfActiveStep={dataForSpecialMalls[mallId] ? dataForSpecialMalls[mallId].mainColor : '#002857'}
                        key={currentStep}
                    />);
    }

    return (
        <div className="steps-wrapper">
            <div className="steps">
                {steps}
            </div>
        </div>
    )
};