const dataForSpecialMalls = {
    fov: {
        mainColor: '#000',
    },
    mov: {
        mainColor: '#000',
    },
    pov: {
        mainColor: '#000',
    },
    paov: {
        mainColor: '#000',
    },
    vov: {
        mainColor: '#000',
    },
    generalStyles: {
        fontColor: '#1D1D1B',
    },
}

export default dataForSpecialMalls;