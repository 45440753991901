import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Privacy ({history}) {
  const { t } = useTranslation();
  const mallId = localStorage.getItem('mallId');

  return (
    <div className="static-page">
      <div className="container">
        <div className="frame">
          <header>
            <button className="go-back" onClick={() => {history.push(`/${mallId}`)}}>
              {t('reservation.go-back')}
            </button>
            <h2>{t('contact.privacy')}</h2>
          </header>
          <article>
            <p>Informativa Privacy relativa a Mypriority.shop</p>
            <p>resa ai sensi dell’art. 13 Regolamento Europeo per la protezione dei dati personali 2016/679 (GDPR)</p>
            <p>La presente Informativa Privacy è resa solo ed esclusivamente per l’applicazione " Mypriority.shop " e non anche per eventuali siti web attraverso i quali l’Utente dovesse accedere per scaricare e/o utilizzare l’applicazione.</p>
            <p>Titolare e Responsabile del Trattamento </p>
            <p>Titolare e Responsabile del trattamento dei dati personali, ai sensi dell’art. 4 punto 7) del GDPR, è </p>
            <p>New Target Web s.r.l. Via G. Carducci, 44 24127 Bergamo C.F./P.I.: IT03114730165 REA Bergamo n.350579 CS: 40.000 Eur (I.V.)</p>
            <p>Indirizzo email del Titolare: privacy@ntnext.it</p>
            <p>Finalità del trattamento e base giuridica</p>
            <p>I dati personali saranno trattati in relazione ai servizi offerti da Mypriority.shop attraverso il portale, esclusivamente per le finalità che rientrano nell'erogazione delle prenotazioni o per gli adempimenti previsti da norme di legge.</p>
            <p>Dati personali raccolti</p>
            <p>I servizi forniti dalla App, nonché le caratteristiche e le funzioni della stessa, non ichiedono la registrazione da parte dell’Utente.</p>
            <p>L’App Mypriority.shop raccoglie esclusivamente i seguenti dati personali: -	Indirizzo email</p>
            <p>Natura obbligatoria o facoltativa del conferimento dei dati e conseguenze di un eventuale rifiuto</p>
            <p>Il conferimento dei dati è necessario per l’erogazione del servizio. Il rifiuto al conferimento non consente l’erogazione del servizio.</p>
            <p>Modalità del trattamento </p>
            <p>I trattamenti avvengono in formato elettronico e durante l’utilizzo dell’App Mypriority.shop. I dati personali dell’Utente sono reindirizzati attraverso connessioni sicure. I dati sopra riportati non sono mai salvati in via definitiva sul dispositivo, sono trattati con logiche strettamente correlate alle finalità suddette, conservati per il solo tempo necessario a conseguire gli scopi per cui sono stati raccolti (ovvero cancellati entro le ore 24 del giorno in cui il servizio di prenotazione viene erogato) e adottando specifiche misure di sicurezza volte a prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati. Nessun dato personale acquisito tramite l’App Mypriority.shop viene diffuso.</p>
            <p>Periodo di conservazione</p>
            <p>I dati verranno conservati per un periodo di tempo non superiore al conseguimento delle finalità, (“principio di limitazione della conservazione”, art.5, del Regolamento UE) o in base alle scadenze previste dalle norme di legge.</p>
            <p>Diritti degli interessati</p>
            <p>L’Utente potrà esercitare i Suoi diritti inviando una email al Responsabile della protezione dei dati. In particolare, potrà richiedere l’accesso ai dati personali che la riguardano, la rettifica o la cancellazione o potrà richiedere la limitazione al trattamento e potrà opporsi al trattamento. Inoltre, avrà il diritto alla portabilità dei dati e, qualora volesse proporre reclamo, potrà presentarlo anche all’autorità Garante per la protezione dei dati personali.</p>
          </article>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Privacy);