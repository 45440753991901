import React from 'react';
import './Loader.scss';

const BigLoader = () => {
    return (
        <div className="base-loader bigLoader"></div>
    );
};

export default BigLoader;
