import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { setCurrentDate, setCurrentPlace, setCurrentTime } from '../platf/actionsCreators';
import { getTimeSlots } from '../platf/actions';
import { formatDate as parseDate } from '../utils';
import { Steps } from './Steps';
import dataForSpecialMalls from '../data/DataForSpecialMalls';
import './styles.scss';


const Timeline = ({
    history,
    persons,
    currentPlace,
    currentDate,
    currentTime,
    loading,
    getTimeSlots,
    slotsList,
    setCurrentTime
}) => {
    const { t, i18n } = useTranslation();
    const validCondition = currentDate && currentTime && currentPlace?.id && persons;
    const { id } = useParams()
    const formatDate = (d) => {
        const day = d.getDate();
        const month = d.toLocaleString(i18n.language === 'it' ? 'it-IT' : 'en-GB', { month: 'long' });

        return `${day} ${month}`;
    };

    const submitReservation = () => {
        const data = {
            date: currentDate,
            parsedDate: parseDate(currentDate),
            persons,
            place: currentPlace,
            time: currentTime,
        }

        history.push(`/${id}/book`, data);
    }

    const logoForSpecialMalls = (
        <img className="logoForSpecialMalls" src={`https://api.mypriority.shop/logos/${id}.png`} alt="logo" />
    )

    const generalSelectTime = (
        <h2>{t('reservation.select-time')}</h2>
    )

    const specialForMallslSelectTime = (
        <h2 style={{ color: dataForSpecialMalls.generalStyles.fontColor }}>
            <span style={{ fontFamily: 'sf_pro_displayregular' }}>{t('reservation.special.select-time')} {t('reservation.special.in-store')}</span>
        </h2>
    )

    const generalInfoAboutChoice = (
        <p>{t('reservation.persons')}: {persons}, {formatDate(currentDate)}</p>
    )

    const specialForMallsInfoAboutChouce = (
        <>
            <p>{t('reservation.persons')}: {persons}</p>
            <p>{t('reservation.date')}: {formatDate(currentDate)}</p>
        </>
    )
    
    useEffect(() => {
        getTimeSlots(currentPlace.id, currentDate, persons)
    }, [currentPlace, currentDate, persons, getTimeSlots])
    
    const isSpecialMall = !!dataForSpecialMalls[id];
    
    return (
        <div className="booking-page">
            <div className="container">
                <div className="frame">
                    <header className="info-wrapper">
                        <div className="info">
                            <button
                                className="go-back"
                                style={{ color: `${isSpecialMall ? dataForSpecialMalls[id].mainColor : '#3170f5'}` }}
                                onClick={() => {
                                    history.push(`/reservations/${id}/calendar`);
                                }}>
                                <svg className="arrow-circle-left" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path
                                        fill={`${isSpecialMall ? dataForSpecialMalls[id].mainColor : '#3170f5'}`}
                                        d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z" />
                                </svg>
                                <span>{t('reservation.go-back-choose-date')}</span>
                            </button>
                            <h1>{t('reservation.your-choose')} <strong>{currentPlace.title}</strong></h1>
                            {isSpecialMall ? specialForMallsInfoAboutChouce : generalInfoAboutChoice}
                        </div>
                        {isSpecialMall && logoForSpecialMalls}
                    </header>
                    <Steps step={4} />
                    <main>
                        <div className="row">
                            <div className="col">
                                {isSpecialMall ? specialForMallslSelectTime : generalSelectTime}
                                {loading ? <div>
                                    <p>Loading...</p>
                                </div> : (
                                        <div className="row">
                                            {slotsList.map((column, index) => (
                                                <>
                                                    <div key={index} className="col-md-4 col-sm-3">
                                                        <div className="available-slots">
                                                            {column && column.map((item, index) => (
                                                                <>
                                                                    <button
                                                                        data-active={currentTime === `${item.from} - ${item.to}`}
                                                                        key={index}
                                                                        className={`slot ${isSpecialMall && id}`}
                                                                        onClick={() => setCurrentTime(`${item.from} - ${item.to}`)}
                                                                    >
                                                                        {item.from} {isSpecialMall ? '' : `- ${item.to}`}
                                                                    </button>
                                                                    {currentTime === `${item.from} - ${item.to}` &&
                                                                        <div className="row header-row timeline-row">
                                                                            <div className="reservation-frame col-md-12 text-right-sm">
                                                                                <button
                                                                                    disabled={!validCondition}
                                                                                    type="submit"
                                                                                    className="button btn-end btn-success"
                                                                                    style={{ background: `${isSpecialMall && dataForSpecialMalls.generalStyles.fontColor}` }}
                                                                                    onClick={() => {
                                                                                        submitReservation()
                                                                                    }}
                                                                                >{t('reservation.continue')}</button>
                                                                            </div>
                                                                        </div>}
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                            {!slotsList.length &&
                                                <div className="col">
                                                    <p>{isSpecialMall ? t('reservation.special.empty-slots') : t('reservation.empty-slots')}.</p>
                                                </div>
                                            }
                                        </div>
                                    )}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </div >
    );
};

const mapStateToProps = ({ steps }) => (
    {
        currentDate: steps.currentDate,
        currentPlace: steps.currentPlace,
        currentTime: steps.currentTime,
        persons: steps.persons,
        loading: steps.loading,
        slotsList: steps.slotsList
    }
);

export default withRouter(connect(mapStateToProps, {
    setCurrentDate,
    setCurrentPlace,
    setCurrentTime,
    getTimeSlots
})(Timeline));
