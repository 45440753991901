import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import dataForSpecialMalls from '../data/DataForSpecialMalls';
import Booking from './Booking';

class Wrapper extends React.Component {
  state = {
    currentPage: 0,
    // scrollEnabled: true,
    lang: 'it',
    navBarVisible: false
  };

  pageScroller = React.createRef();

  componentDidMount() {
    const { i18n } = this.props;
    this.setState({
      lang: i18n.language
    });
  }

  selectLanguage = (lang) => {
    this.setState({
      lang
    });
    this.props.i18n.changeLanguage(lang);
  }

  handlePageChange = (number) => {
    this.pageScroller.current.fullpageApi.moveTo(number);
    this.setState({ currentPage: number });
    this.setState({ navBarVisible: false });
  };

  toggleNavBar = () => {
    this.setState(prevState => ({
      navBarVisible: !prevState.navBarVisible
    }));
  }

  render() {
    const { lang, navBarVisible } = this.state;
    const { t } = this.props;
    const mallId = localStorage.getItem('mallId');

    return (
      <Fragment>
        <nav
          className="navbar fixed-top navbar-expand-lg static-top"
          style={{ 
            background: `${dataForSpecialMalls[mallId] ?
              dataForSpecialMalls[mallId].mainColor
              : 'linear-gradient(to right, #002857 24%, rgba(1, 38, 85, 0.6) 66%, rgba(0, 40, 87, 0.3) 100%)'}`
          }}
        >
          <div className="container">
            <h1 id="logo">
              <span>MyPriority.Shop</span>
              <div className="logo-signature">Powered by</div>
            </h1>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.toggleNavBar}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`${!navBarVisible ? 'collapse' : 'showed'} navbar-collapse`} id="navbarResponsive">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <span
                    className="nav-link"
                    onClick={() => this.props.history.push(`${mallId}/reservations/restore`)}
                  >{t('navigation.reset-reservation')}</span>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/${mallId}/cookies`}>{t('contact.cookies')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/${mallId}/privacy`}>{t('contact.privacy')}</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/${mallId}/terms`}>{t('contact.terms')}</Link>
                </li>
              </ul>
            </div>
            <ul className="language-selector">
              <li className={lang === 'it' ? 'active' : undefined}><span onClick={() => this.selectLanguage('it')}>IT</span></li>
              <li className={lang === 'en' ? 'active' : undefined}><span onClick={() => this.selectLanguage('en')}>ENG</span></li>
            </ul>
          </div>
        </nav>
        <Booking />
      </Fragment>
    );
  }
}

export default withRouter(withTranslation()(Wrapper));
